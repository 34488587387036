/* Hides the reCAPTCHA on every page */
.grecaptcha-badge {
  visibility: hidden !important;
}

body {
  overflow-x: hidden;
}

body[style*="padding-right"] iframe[style*="999999"] {
  right: 31px !important;
}

/* .mapboxgl-control-container {
  opacity: 0 !important;
} */

.search-map-marker-popup,
.search-map-marker-popup .mapboxgl-popup-content {
  padding: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}

.search-map-marker-popup .mapboxgl-popup-tip {
  display: none;
}

.fcal_closed {
  background-color: #ff5630;
  color: #fff;
}